export interface Opinion {
    value?: number;
    icon: string;
    description?: string;
    emoji?: string;
    colour?: string;
    style?: string;
}

const opinions: Record<string, Opinion> = {
    yes: { value: 3, icon: 's:heart', emoji: '❤️', colour: 'pink', style: 'bold' },
    jokingly: { value: 1, icon: 'grin-tongue', emoji: '😜', colour: 'orange' },
    close: { value: 1, icon: 'user-friends', emoji: '🫂', colour: 'red' },
    meh: { value: 0, icon: 'thumbs-up', emoji: '👍' },
    no: { value: -3, icon: 'thumbs-down', emoji: '👎', colour: 'grey', style: 'small' },
};
export default opinions;
