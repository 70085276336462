<template>
    <div class="row">
        <div v-for="columnEventObjects in eventObjectsColumns" class="col-md-6">
            <ul class="list-unstyled">
                <li v-for="event in columnEventObjects" :key="event.name" class="mb-2">
                    <CalendarEvent
                        :event="event"
                        :year="year.year"
                        range
                        month
                        :remove-button="removeButton"
                        @delete="(e: string) => $emit('delete', e)"
                    />
                </li>
            </ul>
        </div>
        <p v-if="maxitems && events.length > maxitems" class="alert alert-danger">
            <Icon v="exclamation-triangle" />
            <T :params="{ maxlength: maxitems }" class="ml-1">crud.validation.listMaxLength</T>
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Calendar, Year } from '../src/calendar/helpers.ts';
import type { Event, CustomEvent } from '../src/calendar/helpers.ts';

export default defineComponent({
    props: {
        year: { required: true, type: Year },
        events: { required: true, type: Array as PropType<(string | CustomEvent)[]> },
        removeButton: { type: Boolean },
        maxitems: { default: null, type: Number as PropType<number | null> },
    },
    emits: ['delete'],
    computed: {
        eventObjects(): Event[] {
            return Calendar.generatePersonalCalendarEvents(this.events, this.year);
        },
        eventObjectsColumns(): Event[][] {
            if (this.eventObjects.length < 8) {
                return [this.eventObjects];
            }

            const cutoff = Math.ceil(this.eventObjects.length / 2);

            return [
                this.eventObjects.slice(0, cutoff),
                this.eventObjects.slice(cutoff),
            ];
        },
    },
});
</script>
